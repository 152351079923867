import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import auth from './auth/auth'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import categories from './categories/categories'
import raffles from './raffles/raffles'
import users from './users/users'
import rooms from './rooms/rooms'
import tickets from './tickets/tickets'
/* eslint-disable-next-line */
import payments from './payments/payments'
import credits from './credits/credits'
import pagadito from './pagadito/pagadito'
import notifications from './notifications/notifications'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    auth,
    categories,
    raffles,
    users,
    rooms,
    tickets,
    payments,
    credits,
    pagadito,
    notifications,
  },
  strict: process.env.DEV,
})

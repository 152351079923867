<template>
  <div>
    <b-navbar>
      <b-navbar-nav
        class="nav-item"
        :class="{focus: $route.name === 'home'}"
        @click="$router.push({ name: 'home' })"
      >
        <feather-icon icon="HomeIcon" />
      </b-navbar-nav>

      <b-navbar-nav
        v-if="currentUser && cart.length > 0"
        class="nav-item"
        :class="{focus: $route.name === 'cart-checkout'}"
        @click="$router.push({ name: 'cart-checkout' })"
      >
        <feather-icon
          :badge="totalTickets"
          class="cart-icon alt-color"
          icon="ShoppingCartIcon"
        />
      </b-navbar-nav>

      <b-navbar-nav
        class="nav-item"
        :class="{focus: $route.name === 'Sorteos'}"
        @click="$router.push({ name: 'Sorteos' })"
      >
        <feather-icon icon="GiftIcon" />
      </b-navbar-nav>

      <b-navbar-nav
        v-if="currentUser"
        class="nav-item"
        :class="{focus: $route.name === 'user'}"
        @click="$router.push({ name: 'user', params: { id: currentUser.id } })"
      >
        <feather-icon icon="UserIcon" />
      </b-navbar-nav>

    </b-navbar>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  data() {
    return {
      timmer: null,
    }
  },
  computed: {
    ...mapGetters('auth', ['currentUser']),
    ...mapGetters('tickets', ['cart']),
    totalTickets() {
      let tickets = 0
      this.cart.forEach(raffle => {
        tickets += raffle.tickets.length
      })
      return tickets
    },
  },
  created() {
    this.getUserCart({ userId: this.currentUser.id })
    this.timer = setInterval(this.updateUserCart, 10000)
  },
  methods: {
    ...mapActions('tickets', ['checkout', 'getUserCart']),
    updateUserCart() {
      this.getUserCart({ userId: this.currentUser.id })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/core/colors/palette-variables.scss';

nav{
  display: flex;
  justify-content: space-around;
  background-color: $primary;
  position: fixed;
  bottom: 0;
  width: 100vw;
  z-index: 100;
}

.badge-primary {
  background-color: $info !important;
}

.nav-item{
  display: flex;
  align-items: center;
  text-decoration: none;
  color: white;
  padding: 12px 16px;
  border-radius: 50px;
  background-color: rgba(255, 255, 255, 0);
}

.focus{
  text-decoration: none;
  color: white;
  background-color: rgba(255, 255, 255, 0.2);
  transition: .2s;
  margin-left: 8px;
  transition: .2s;
}
</style>

import axios from '@axios'

const state = () => ({
  categories: {
    data: [],
    meta: {
      pagination: {
        page: 1,
        per_page: 10,
        total_objects: 0,
      },
    },
  },
  currentCategory: {},
  loading: false,
})

const getters = {
  // eslint-disable-next-line
  categories: state => state.categories.data,
  // eslint-disable-next-line
  currentCategory: state => state.currentCategory,
  // eslint-disable-next-line
  pagination: state => state.categories.meta.pagination,
  // eslint-disable-next-line
  categoriesCount: state => state.categories.meta.pagination.total_objects,
  // eslint-disable-next-line
  loading: state => state.loading,
}

const mutations = {
  // eslint-disable-next-line
  setCategories(state, payload) {
    state.categories = payload
  },
  // eslint-disable-next-line
  setCategory(state, payload) {
    state.currentCategory = payload
  },
  // eslint-disable-next-line
  setLoading(state, payload) {
    state.loading = payload
  },
}

const actions = {
  getCategories(context, { by_active_status, by_name }) {
    return new Promise((resolve, reject) => {
      context.commit('setLoading', true)
      axios
        .get('/v1/categories', {
          params: { by_active_status, by_name },
        })
        .then(response => {
          context.commit('setCategories', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          context.commit('setLoading', false)
        })
    })
  },
  getCategory(context, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/v1/categories/${payload}`)
        .then(response => {
          context.commit('setCategory', response.data)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
        .finally()
    })
  },
  addCategory(context, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post('/v1/categories', {
          category: {
            name: payload.name,
            active_status: true,
          },
        })
        .then(() => {
          resolve()
        })
        .catch(() => {
          reject()
        })
    })
  },
  editCategory(context, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/v1/categories/${payload.id}`, {
          category: {
            name: payload.name,
            active_status: payload.active_status,
          },
        })
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
        .finally()
    })
  },
  deleteCategory(context, { category }) {
    axios
      .put(`v1/categories/${category.id}`, {
        category,
      })
      .then(() => {
        context.dispatch('getCategories', { page: context.state.categories.meta.pagination.page })
      })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

// eslint-disable

const state = () => ({
  connection: null,
})

const getters = {

  // eslint-disable-next-line
  connection(state) {
    return state.connection
  },
}

const mutations = {
  // eslint-disable-next-line
  SET_CONNECTION(state, payload) {
    state.connection = payload
  },
}

const actions = {
  connectNotifications(ctx, tkn) {
    return new Promise(resolve => {
      const token = localStorage.getItem('accessToken')
      const connection = new WebSocket(`wss://api.ticketcrush.com.mx/cable?token=${tkn || token}`)
      connection.onmessage = event => {
        const data = JSON.parse(event.data)
        /* eslint-disable-next-line */
        if (data.message && data.message.notification_type === 'ticket_purchase') {
          ctx.commit('credits/SET_CREDITS', data.message.content_message, { root: true })
        }
      }
      connection.onopen = () => {
        // eslint-disable-next-line
        connection.send(JSON.stringify({ "command": "subscribe","identifier":"{\"channel\":\"ApplicationCable::NotificationChannel\"}" }))
        ctx.commit('SET_CONNECTION', connection)
        resolve()
      }
    })
  },
  disconnectNotifications(ctx) {
    const socket = ctx.state.connection
    socket.close()
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

import axios from '@axios'

const state = () => ({
  users: {
    data: [],
    meta: {
      pagination: {
        page: 1,
        per_page: 10,
        total_objects: 0,
      },
    },
  },
  currentUser: {},
  userCredits: {},
  loading: false,
})

const getters = {
  // eslint-disable-next-line
  users: state => state.users.data,
  // eslint-disable-next-line
  currentUser: state => state.currentUser,
  // eslint-disable-next-line
  userCredits: state => state.userCredits,
  // eslint-disable-next-line
  pagination: state => state.users.meta.pagination,
  // eslint-disable-next-line
  usersCount: state => state.users.meta.pagination.total_objects,
  // eslint-disable-next-line
  loading: state => state.loading,
}

const mutations = {
  // eslint-disable-next-line
  setUsers(state, payload) {
    state.users = payload
  },
  // eslint-disable-next-line
  setUser(state, payload) {
    state.currentUser = payload
  },
  // eslint-disable-next-line
  setLoading(state, payload) {
    state.loading = payload
  },
  // eslint-disable-next-line
  SET_USER_CREDITS(state, payload) {
    state.userCredits = payload
  },
}

const actions = {
  getUsers(context, { by_active_status }) {
    return new Promise((resolve, reject) => {
      context.commit('setLoading', true)
      axios
        .get('/v1/users', {
          params: { by_active_status },
        })
        .then(response => {
          context.commit('setUsers', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          context.commit('setLoading', false)
        })
    })
  },
  getUser(context, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/v1/users/${payload}`)
        .then(response => {
          context.commit('setUser', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally()
    })
  },
  getUserCredits(context, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/v1/users/${payload}/credits`)
        .then(response => {
          context.commit('SET_USER_CREDITS', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally()
    })
  },
  addUser(context, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post('/v1/users', {
          user: payload,
        })
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  editUser(context, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/v1/users/${payload.id}`, {
          user: payload,
        })
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
        .finally()
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

import axios from '@axios'

const state = () => ({
  payments: {
    data: [],
    meta: {
      pagination: {
        page: 1,
        per_page: 10,
        total_objects: 0,
      },
    },
  },
  loading: false,
})

const getters = {
  // eslint-disable-next-line
  payments: state => state.payments.data,
}

const mutations = {
  // eslint-disable-next-line
  SET_PAYMENTS(state, payload) {
    state.payments = payload.data
  },
  // eslint-disable-next-line
  SET_LOADING(state, payload) {
    state.loading = payload
  },
}

const actions = {
  getPayments(context, params = { by_user_id: null }) {
    return new Promise((resolve, reject) => {
      context.commit('SET_LOADING', true)
      axios
        .get('/v1/payments', {
          params,
        })
        .then(response => {
          context.commit('SET_PAYMENTS', response)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          context.commit('SET_LOADING', false)
        })
    })
  },

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

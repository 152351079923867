import axios from '@axios'

const state = () => ({
  loading: false,
})

const getters = {
}

const mutations = {

}

const actions = {
  buyCreditsPagadito(context, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post('/v1/pagadito_checkout', {
          checkout_details: {
            resource_id: payload,
          },
        })
        .then(response => {
          window.location.href = response.data.transaction_response.pagadito_url
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
        })
    })
  },

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

import axios from '@axios'
import reservations from '@/libs/axiosReservations'

const state = {
  cart: [],
}

const getters = {
  // eslint-disable-next-line
  cart(state) {
    return state.cart
  },
}

const mutations = {
  // eslint-disable-next-line
  ADD_RESERVED_TICKET_TO_CART(state, payload) {
    state.cart = payload
  },
  // eslint-disable-next-line
  REMOVE_TICKET_FROM_CART(state, payload) {
    state.cart.splice(state.cart[payload], 1)
  },
  // eslint-disable-next-line
  CLEAR_CART(state) {
    state.cart = []
  },
}

const actions = {
  reserveTicket(context, { roomId, ticketId, userId }) {
    return new Promise((resolve, reject) => {
      reservations
        .put(`/rooms/${roomId}/tickets/${ticketId}/reserve?userId=${userId}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  releaseTicket(_, { roomId, ticketId, userId }) {
    return new Promise((resolve, reject) => {
      reservations
        .put(`/rooms/${roomId}/tickets/${ticketId}/release?userId=${userId}`)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getUserCart(context, { userId }) {
    return new Promise(resolve => {
      reservations
        .get(`/userCarts/${userId}`)
        .then(response => {
          const tempCart = []
          const tickets = response.data.reservedTickets
          /* eslint-disable-next-line */
          for (const [key, value] of Object.entries(tickets)) {
            if (value.tickets.length > 0) {
              tempCart.push(
                {
                  raffleId: key,
                  raffleName: value.title,
                  img: value.imageUrl,
                  tickets: value.tickets,
                  createdAt: response.data.createdAt,
                  expiresAt: response.data.expiresAt,
                },
              )
            }
          }
          context.commit('ADD_RESERVED_TICKET_TO_CART', tempCart)
          resolve(response.data)
        })
        .catch(error => {
          context.commit('CLEAR_CART')
          // eslint-disable-next-line
          console.log(error)
        })
    })
  },
  /*
      [
        {
          raffleName: '',
          img: ''
          tickets: [
            {
              folio,
              id,
              cost
            }
          ],
        }
      ]
  */
  checkout(context) {
    return new Promise((resolve, reject) => {
      const tickets = []
      context.getters.cart.forEach(raffle => { raffle.tickets.forEach(ticket => { tickets.push(ticket.id) }) })
      axios
        .post('/v1/tickets/checkout', {
          reserved_tickets: tickets,
        })
        .then(response => {
          context.commit('CLEAR_CART')
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

/* eslint-disable no-shadow */
/* eslint-disable camelcase */
import axios from '@axios'

const state = () => ({
  raffles: {
    data: [],
    meta: {
      pagination: {
        page: 1,
        per_page: 10,
        total_objects: 0,
      },
    },
  },
  featureRaffles: {
    data: [],
    meta: {
      pagination: {
      },
    },
  },
  rafflesWon: {
    data: [],
    meta: {
      pagination: {
        page: 1,
        per_page: 10,
        total_objects: 0,
      },
    },
  },
  currentRaffle: {},
  tickets: {
    data: [],
    meta: {
      pagination: {
        page: 1,
        per_page: 10,
        total_objects: 0,
      },
    },
  },
  loading: false,
})

const getters = {
  raffles: state => state.raffles.data,
  featureRaffles: state => state.featureRaffles.data,
  rafflesWon: state => state.rafflesWon.data,
  currentRaffle: state => state.currentRaffle,
  tickets: state => state.tickets.data,
  pagination: state => state.raffles.meta.pagination,
  rafflesCount: state => state.raffles.meta?.pagination?.total_objects,
  loading: state => state.loading,
}

const mutations = {
  setRaffles(state, payload) {
    state.raffles = payload
  },
  setFeatureRaffles(state, payload) {
    state.featureRaffles = payload
  },
  setRafflesWon(state, payload) {
    state.rafflesWon = payload
  },
  setRaffle(state, payload) {
    state.currentRaffle = payload
  },
  setTickets(state, payload) {
    state.tickets = payload
  },
  setLoading(state, payload) {
    state.loading = payload
  },
  RESET_RAFFLE(state) {
    state.currentRaffle = {}
  },
  RESET_RAFFLES(state) {
    state.raffles = {
      data: [],
      meta: {
        pagiantion: {},
      },
    }
  },
}

const actions = {
  getRafflesWinners(context, params) {
    return new Promise((resolve, reject) => {
      context.commit('setLoading', true)
      axios
        .get('/v1/raffle_winners', {
          params,
        })
        .then(response => {
          context.commit('setRafflesWon', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          context.commit('setLoading', false)
        })
    })
  },
  getRafflesWon(context, params) {
    return new Promise((resolve, reject) => {
      context.commit('setLoading', true)
      axios
        .get('/v1/raffles_won', {
          params,
        })
        .then(response => {
          context.commit('setRafflesWon', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          context.commit('setLoading', false)
        })
    })
  },
  getRaffles(context, params) {
    return new Promise((resolve, reject) => {
      context.commit('setLoading', true)
      axios
        .get('/v1/raffles', {
          params,
        })
        .then(response => {
          context.commit('setRaffles', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          context.commit('setLoading', false)
        })
    })
  },
  getTickets(context, params) {
    return new Promise((resolve, reject) => {
      context.commit('setLoading', true)
      axios
        // eslint-disable-next-line camelcase
        .get(`/v1/raffles/${params.raffle_id}/tickets`, {
          params: {
            by_folio: params.by_folio,
            meta: params.meta,
          },
        })
        .then(response => {
          context.commit('setTickets', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          context.commit('setLoading', false)
        })
    })
  },
  getClientRaffles(context, params) {
    return new Promise((resolve, reject) => {
      context.commit('setLoading', true)
      axios
        .get('/v1/published_raffles', {
          params,
        })
        .then(response => {
          if (params.by_is_featured) {
            context.commit('setFeatureRaffles', response.data)
          } else {
            context.commit('setRaffles', response.data)
          }
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          context.commit('setLoading', false)
        })
    })
  },
  getRaffle(context, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/v1/raffles/${payload}`)
        .then(response => {
          context.commit('setRaffle', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally()
    })
  },
  saveRaffle(context, payload) {
    return new Promise((resolve, reject) => {
      context.commit('setLoading', true)
      axios
        .post('/v1/raffles', {
          raffle: payload,
        })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          context.commit('setLoading', false)
        })
    })
  },
  deleteRaffleImage(context, payload) {
    return new Promise((resolve, reject) => {
      context.commit('setLoading', true)
      axios
        .delete(`/v1/raffles/${payload.id}/remove_image/${payload.image_id}`)
        .then(response => {
          context.commit('setRaffle', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          context.commit('setLoading', false)
        })
    })
  },
  publishRaffle(context, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/v1/raffles/${payload.id}/publish`, {
          raffle: payload,
        })
        .then(response => {
          resolve(response.data)
          context.dispatch('getRaffles', { by_active_status: true })
        })
        .catch(error => {
          reject(error)
        })
        .finally()
    })
  },
  setRaffleWinner(context, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/v1/raffles/${payload.raffle_id}/proclaim_winner/${payload.ticket_id}`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally()
    })
  },
  updateRafflePriority(context, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/v1/raffles/${payload.id}`, {
          raffle: {
            is_featured: payload.is_featured,
            is_bannered: payload.is_bannered,
            active_status: payload.active_status,
          },
        })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => reject(error))
        .finally()
    })
  },
  editRaffle(context, payload) {
    return new Promise((resolve, reject) => {
      let temp_categories_ids = []
      if (payload.active_status !== false) {
        if (payload.category_ids) {
          // Save the ids of the old registered categories
          const old_categories = payload.categories.map(cat => cat.id)
          // Compare the category_ids with the old ids and get only the different ones
          temp_categories_ids = payload.category_ids.filter(x => !old_categories.includes(x))
        } else {
          temp_categories_ids = payload.categories.reduce(x => x.id)
        }
      }
      axios
        .put(`/v1/raffles/${payload.id}`, {
          raffle: {
            ...payload,
            category_ids: temp_categories_ids,
          },
        })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => reject(error))
        .finally()
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

// axios
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Vue from 'vue'

const token = localStorage.getItem('accessToken')

const reservations = axios.create({
  // You can add your headers here
  // ================================
  baseURL: process.env.VUE_APP_RESERVATIONS_URL,
  // timeout: 1000,
  headers: {
    Authorization: `Bearer ${token}`,
  },
})

// toast notifications when error 🧨💣
reservations.interceptors.response.use(
  response => response,
  error => {
    /*
    Error 418 es cuando el usuario ya no tiene ningún
    carrito de compras asociado a el mismo, no imprimimos el msg de error
    */
    if (error.response.status !== 418 && error.response.data.errors) {
      const vm = new Vue()
      error.response.data.errors.Messages.forEach(err => {
        vm.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'AlertCircleIcon',
            variant: 'danger',
            text: err,
          },
        })
      })
    }
    return Promise.reject(error)
  },
)

export default reservations

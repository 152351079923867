import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Vue from 'vue'
// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: process.env.VUE_APP_API_URL,
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})

// toast notifications when error 🧨💣
axiosIns.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status !== 401) {
      const vm = new Vue()
      if (error.response.data.messages) {
        error.response.data.messages.forEach(err => {
          vm.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: err,
            },
          })
        })
      }
      if (error.response.data.errors) {
        vm.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'AlertCircleIcon',
            variant: 'danger',
            text: error.response.data.errors,
          },
        })
      }
      if (error.response.data.message) {
        vm.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'AlertCircleIcon',
            variant: 'danger',
            text: error.response.data.message,
          },
        })
      }
    }
    return Promise.reject(error)
  },
)

Vue.prototype.$http = axiosIns

export default axiosIns

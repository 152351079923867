import reservations from '@/libs/axiosReservations'

const state = {
  rooms: null,
  currentRoom: {
    numberOfTickets: 0,
    categories: [],
    tickets: [],
  },
}

const getters = {
  // eslint-disable-next-line
  rooms(state) {
    return state.rooms
  },
  // eslint-disable-next-line
  currentRoom(state) {
    return state.currentRoom
  },
}

const mutations = {
  // eslint-disable-next-line
  setRooms(state, payload) {
    state.rooms = payload
  },
  // eslint-disable-next-line
  setRoom(state, payload) {
    state.currentRoom = payload.data
  },
  // eslint-disable-next-line
  UPDATE_TICKET(state, payload) {
    state.currentRoom.tickets.forEach((x, n) => {
      if (payload.ticketId === x.id) {
        state.currentRoom.tickets[n].status = payload.status
      }
    })
  },
}

const actions = {
  getRooms(context) {
    return new Promise((resolve, reject) => {
      reservations
        .get('/rooms')
        .then(response => {
          context.commit('setRooms', response)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getRoom(context, payload) {
    return new Promise((resolve, reject) => {
      reservations
        .get(`/rooms/${payload}`)
        .then(response => {
          context.commit('setRoom', response)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

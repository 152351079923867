/* eslint-disable */
import axios from '@axios'

const state = () => ({
  creditHistories: {
    data: [],
    meta: {
      pagination: {
        page: 1,
        per_page: 10,
        total_objects: 0,
      },
    },
  },
  loading: false,
  creditPackages: {
    data: [],
    meta: {
      pagination: {
        page: 1,
        per_page: 10,
        total_objects: 0,
      },
    },
  },
  credits: null,
})

const getters = {
  creditHistories: state => state.creditHistories.data,
  totalCreditHistories: state => state.creditHistories.meta.pagination.total_objects,
  creditPackages: state => state.creditPackages.data,
  totalCreditPackages: state => state.creditPackages.meta.pagination.total_objects,
  credits: state => state.credits,
}

const mutations = {
  SET_CREDIT_HISTORIES(state, payload) {
    state.creditHistories = payload.data
  },
  SET_LOADING(state, payload) {
    state.loading = payload
  },
  SET_CREDIT_PACKAGES(state, payload) {
    state.creditPackages = payload
  },
  SET_CREDITS(state, payload) {
    state.credits = payload
  },
}

const actions = {
  getCretitHistories(context, params) {
    return new Promise((resolve, reject) => {
      context.commit('SET_LOADING', true)
      axios
        .get('/v1/credit_histories', {
          params,
        })
        .then(response => {
          context.commit('SET_CREDIT_HISTORIES', response)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          context.commit('SET_LOADING', false)
        })
    })
  },
  getCreditPackages(context, params) {
    return new Promise((resolve, reject) => {
      axios
        .get('/v1/credit_packages', {
          params,
        })
        .then(response => {
          context.commit('SET_CREDIT_PACKAGES', response.data)
          resolve()
        })
        .catch(() => {
          reject()
        })
    })
  },
  getCreditPackage(context, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/v1/credit_packages/${id}`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  addCreditPackage(context, creditPackage) {
    return new Promise((resolve, reject) => {
      axios
        .post('/v1/credit_packages', {
          credit_package: creditPackage,
        })
        .then(() => {
          resolve()
        })
        .catch(() => {
          reject()
        })
    })
  },
  editCreditPackage(context, creditPackage) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/v1/credit_packages/${creditPackage.id}`, {
          credit_package: creditPackage
        })
        .then(() => {
          resolve()
        })
        .catch(() => {
          reject()
        })
    })
  },
  getCredits(context, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/v1/users/${id}/credits`)
        .then(response => {
          context.commit('SET_CREDITS', response.data.credits)
        })
    })
  }

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

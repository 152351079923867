const token = localStorage.getItem('accessToken')

const notificationsWebSocket = tkn => {
  const connection = new WebSocket(`wss://api.ticketcrush.com.mx/cable?token=${tkn || token}`)
  connection.onmessage = event => {
    // eslint-disable-next-line
    console.log(event.data)
  }
  connection.onopen = () => {
    // eslint-disable-next-line
    connection.send(JSON.stringify({ "command": "subscribe","identifier":"{\"channel\":\"ApplicationCable::NotificationChannel\"}" }))
  }
}

const disconectNotifications = tkn => {
  const connection = new WebSocket(`wss://api.ticketcrush.com.mx/cable?token=${tkn || token}`)
  connection.close()
}

// eslint-disable-next-line
export {
  notificationsWebSocket,
  disconectNotifications,
}

import Vue from 'vue'
import VueRouter from 'vue-router'
import auth from './routes/auth'
// eslint-disable-next-line
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
// eslint-disable-next-line
import store from '@/store'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...auth,
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/home/Home.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/sorteos',
      name: 'Sorteos',
      component: () => import('@/views/Raffles.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/pago',
      name: 'cart-checkout',
      component: () => import('@/views/CheckoutPayment.vue'),
      meta: {
        layout: 'full',
        requiresAuth: true,
        contentClass: 'ecommerce-application',
      },
    },

    // Users
    {
      path: '/usuarios',
      name: 'Users',
      component: () => import('@/views/users/Users.vue'),
    },
    {
      path: '/usuarios/nuevo',
      name: 'UsersForm',
      component: () => import('@/views/users/UsersForm.vue'),
      meta: {
        pageTitle: 'Registrar usuario',
        breadcrumb: [
          {
            text: 'Usuarios',
            to: { name: 'Users' },
          },
          {
            text: 'Formulario de usuario',
            active: true,
          },
        ],
      },
    },
    {
      path: '/usuarios/editar/:id',
      name: 'UsersEditForm',
      component: () => import('@/views/users/UsersForm.vue'),
      meta: {
        pageTitle: 'Editar usuario',
        breadcrumb: [
          {
            text: 'Usuarios',
            to: { name: 'Users' },
          },
          {
            text: 'Formulario de usuario',
            active: true,
          },
        ],
      },
    },
    {
      path: '/perfil/editar/:id',
      name: 'CustomerEditForm',
      component: () => import('@/views/users/CustomerEdit.vue'),
      meta: {
        pageTitle: 'Editar perfil',
        layout: 'full',
      },
    },
    {
      path: '/usuario/:id',
      name: 'user',
      component: () => import('@/views/users/User.vue'),
      meta: {
        layout: 'full',
      },
    },

    // Categories
    {
      path: '/categorias',
      name: 'Categories',
      component: () => import('@/views/categories/Categories.vue'),
    },
    {
      path: '/categorias/nueva',
      name: 'CategoriesForm',
      component: () => import('@/views/categories/CategoriesForm.vue'),
      meta: {
        pageTitle: 'Registrar categoría',
        breadcrumb: [
          {
            text: 'Categorias',
            to: { name: 'Categories' },
          },
          {
            text: 'Formulario de categoria',
            active: true,
          },
        ],
      },
    },
    {
      path: '/categorias/editar/:id',
      name: 'CategoriesEditForm',
      component: () => import('@/views/categories/CategoriesForm.vue'),
      meta: {
        pageTitle: 'Editar categoría',
        breadcrumb: [
          {
            text: 'Categorias',
            to: { name: 'Categories' },
          },
          {
            text: 'Formulario de categoria',
            active: true,
          },
        ],
      },
    },

    // Raffles
    {
      path: '/adminSorteos',
      name: 'RafflesList',
      component: () => import('@/views/RafflesList.vue'),
    },
    {
      path: '/sorteo/nuevo',
      name: 'CreateRaffle',
      component: () => import('@/views/RaffleFormGrid.vue'),
      meta: {
        pageTitle: 'Registrar sorteo',
        breadcrumb: [
          {
            text: 'Sorteos',
            to: { name: 'RafflesList' },
          },
          {
            text: 'Formulario de sorteo',
            active: true,
          },
        ],
      },
    },
    {
      path: '/sorteo/editar/:id',
      name: 'RaffleEditForm',
      component: () => import('@/views/RaffleFormGrid.vue'),
      meta: {
        pageTitle: 'Editar sorteo',
        breadcrumb: [
          {
            text: 'Sorteos',
            to: { name: 'RafflesList' },
          },
          {
            text: 'Formulario de sorteo',
            active: true,
          },
        ],
      },
    },
    {
      path: '/raffle/:id',
      name: 'raffle',
      component: () => import('@/views/raffles/RaffleView.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/creditos',
      name: 'credits-bundle',
      component: () => import('@/views/credits/CreditPackagesList.vue'),
    },
    {
      path: '/creditos/nuevo-paquete',
      name: 'new-credit-bundle',
      component: () => import('@/views/credits/CreditPackageForm.vue'),
    },
    {
      path: '/creditos/editar-paquete/:credit_id',
      name: 'edit-credit-bundle',
      component: () => import('@/views/credits/CreditPackageForm.vue'),
    },
    {
      path: '/ganador/:raffle_id',
      name: 'register-winner',
      component: () => import('@/views/RaffleWinner.vue'),
      meta: {
        pageTitle: 'Registrar ganador',
        breadcrumb: [
          {
            text: 'Sorteo',
            to: { name: 'raffles_list' },
          },
          {
            text: 'Registro de ganador',
            active: true,
          },
        ],
      },
    },
    {
      path: '/get-credits',
      name: 'getCredits',
      component: () => import('@/views/credits/GetCredits.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const userData = getUserData()
  const isLoggedIn = isUserLoggedIn()

  if (to.meta.requiresAuth) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })
  }

  // TODO: Mandar a vista de token expired no login.
  if (isLoggedIn) {
    store.commit('auth/setCurrentUser', userData)
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next(getHomeRouteForLoggedInUser(userData ? userData.role_name : null))
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router

import Vue from 'vue'
import dayjs from 'dayjs'
import { isToday } from './utils'

Vue.filter('money', value => {
  const val = (value / 1).toFixed(2)
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
})
Vue.filter('credits', value => {
  const val = (value / 1).toFixed(0)
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
})

Vue.filter('date', value => dayjs(value).format('DD/MMM/YY'))

Vue.filter('dateNtime', value => dayjs(value).format('DD/MMM/YY HH:mm a'))
Vue.filter('time', value => dayjs(value).format('HH:mm'))

Vue.filter('role_name', value => {
  const roles = {
    admin: 'Administrador',
    customer: 'Cliente',
  }
  return roles[value]
})

Vue.filter('payment_type', value => {
  const status = {
    credit: 'Credito',
    debit: 'Debito',
  }
  return status[value]
})

Vue.filter('payment_status', value => {
  const status = {
    invalidated: 'Calcelado',
    validated: 'Aceptado',
    pending: 'En proceso',
  }
  return status[value]
})

Vue.filter('status', value => {
  const status = {
    false: 'Inactivo',
    true: 'Activo',
  }
  return status[value]
})

Vue.filter('raffle_status', value => {
  const status = {
    false: 'Pendiente',
    true: 'Publicado',
  }
  return status[value]
})

export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

export const title = (value, replacer = ' ') => {
  if (!value) return ''
  const str = value.toString()

  const arr = str.split(replacer)
  const capitalizedArray = []
  arr.forEach(word => {
    const capitalized = word.charAt(0).toUpperCase() + word.slice(1)
    capitalizedArray.push(capitalized)
  })
  return capitalizedArray.join(' ')
}

export const avatarText = value => {
  if (!value) return ''
  const nameArray = value.split(' ')
  return nameArray.map(word => word.charAt(0).toUpperCase()).join('')
}

/**
 * Format and return date in Humanize format
 * Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 * Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 * Return short human friendly month representation of date
 * Can also convert date to only time if date is of today (Better UX)
 * @param {String} value date to format
 * @param {Boolean} toTimeForCurrentDay Shall convert to time if day is today/current
 */
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// Strip all the tags from markup and return plain text
export const filterTags = value => value.replace(/<\/?[^>]+(>|$)/g, '')

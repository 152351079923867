import Vue from 'vue'
import { BootstrapVue, ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

// VueCroppie
import VueCroppie from 'vue-croppie'
import 'croppie/croppie.css'

// Google Auth API
import GAuth from 'vue-google-oauth2'

import router from './router'
import store from './store'
import App from './App.vue'
import '@core/utils/filter'
import '@core/utils/dayjs'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/sweet-alerts'
import '@/libs/toastification'

const gauthOption = {
  clientId: '140464568431-j8b2nu0jmeise8crurbbj06b3o5gjh25.apps.googleusercontent.com',
  scope: 'profile email',
  prompt: 'select_account',
  fetch_basic_profile: true,
}

Vue.use(GAuth, gauthOption)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

Vue.use(BootstrapVue)

// VueCroppie
Vue.use(VueCroppie)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
